const schoolOptionContainer = () =>
  import('@/views/containers/schoolOptionContainer')

export default {
  path: '/school',
  redirect: '/',
  component: schoolOptionContainer,
  props: true,
  meta: {
    role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
    permission: [
      'ROLE_ADMIN',
      'AUTH_WRITE',
      'GET_ACCOUNT_LIST',
      'GET_COMPANY_LIST',
      'LOGOUT'
    ]
  },
  children: [
    {
      path: ':tagId',
      name: 'SchoolOverview',
      redirect: ':tagId/overview',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      meta: {
        role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
        permission: ['LOGOUT']
      },
      children: [
        {
          path: 'overview',
          props: true,
          component: () => import('@/views/school/overview/index'),
          meta: {
            bgCSS: 'BG-01',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'chart',
          props: true,
          component: () => import('@/views/school/chart'),
          meta: {
            bgCSS: 'BG-04',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'analysis',
          props: true,
          component: () => import('@/views/school/Analysis'),
          meta: {
            bgCSS: 'BG-04',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'comparison',
          props: true,
          component: () => import('@/views/school/comparison/index'),
          meta: {
            bgCSS: 'BG-04',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'info',
          props: true,
          component: () => import('@/views/school/info/index'),
          meta: {
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'report',
          props: true,
          component: () => import('@/views/school/report/index'),
          meta: {
            bgCSS: 'BG-04',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'warning',
          props: true,
          component: () => import('@/views/school/warning'),
          meta: {
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'action',
          props: true,
          component: () => import('@/views/school/action'),
          meta: {
            name: '冷氣控制',
            search: true,
            bgCSS: 'BG-01',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'group',
          props: true,
          component: () => import('@/views/school/group/index'),
          meta: {
            name: '群組設定',
            bgCSS: 'BG-01',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'account',
          props: true,
          component: () => import('@/views/school/account'),
          meta: {
            name: '帳號管理',
            bgCSS: 'BG-01',
            role: ['CITY_ADMIN', 'SCH_ADMIN'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'schedule',
          props: true,
          component: () => import('@/views/school/schedule/index'),
          meta: {
            name: '排程列表',
            bgCSS: 'BG-04',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'schedule/create',
          redirect: 'schedule/create/relay'
        },
        {
          path: 'schedule/create/relay',
          props: true,
          component: () => import('@/views/school/schedule/editOrCreate'),
          name: 'RelayControlSchedule',
          meta: {
            name: '新增電驛控制排程',
            bgCSS: 'BG-01',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'schedule/create/temperature',
          props: (route) => {
            return { ...route.params, actionType: 'temperature' }
          },
          component: () => import('@/views/school/schedule/editOrCreate'),
          meta: {
            name: '新增溫度設定排程',
            bgCSS: 'BG-01',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'schedule/:id',
          redirect: 'schedule/:id/relay'
        },
        {
          path: 'schedule/:id/relay',
          props: true,
          component: () => import('@/views/school/schedule/editOrCreate'),
          meta: {
            name: '電驛控制排程設定',
            bgCSS: 'BG-01',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'schedule/:id/temperature',
          props: (route) => {
            return { ...route.params, actionType: 'temperature' }
          },
          component: () => import('@/views/school/schedule/editOrCreate'),
          meta: {
            name: '溫度設定程排程設定',
            bgCSS: 'BG-01',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'deposit',
          props: true,
          component: () => import('@/views/school/deposit'),
          meta: {
            name: '儲值結帳紀錄報表',
            bgCSS: 'BG-04',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_USER', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'unloading',
          props: true,
          component: () => import('@/views/school/unloading/index'),
          meta: {
            name: '超約卸載動作設定',
            bgCSS: 'BG-01',
            role: ['CITY_ADMIN', 'SCH_ADMIN', 'SCH_AFFAIRS'],
            permission: ['LOGOUT']
          }
        },
        {
          path: 'ADR',
          props: true,
          redirect: 'ADR/history',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'settings',
              props: true,
              component: () => import('@/views/school/ADR/index'),
              name: 'AdrSettings',
              meta: {
                name: 'ADR設定',
                bgCSS: 'BG-01',
                role: ['CITY_ADMIN', 'SCH_ADMIN'],
                permission: ['LOGOUT']
              }
            },
            {
              path: 'history',
              props: true,
              component: () => import('@/views/school/ADR/History'),
              name: 'AdrHistory',
              meta: {
                name: 'ADR資訊',
                bgCSS: 'BG-03',
                role: ['CITY_ADMIN', 'SCH_ADMIN'],
                permission: ['LOGOUT']
              }
            }
          ]
        },
        {
          path: 'device',
          props: true,
          redirect: 'device/damaged',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'damaged',
              props: true,
              component: () => import('@/views/school/device/damaged/index'),
              name: 'Damaged',
              meta: {
                name: '設備異常管理',
                bgCSS: 'BG-03',
                role: ['SCH_ADMIN'],
                permission: ['LOGOUT']
              }
            }
          ]
        }
      ]
    }
  ]
}
