import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import settings from '../settings'

const ModulesFile = require.context('./modules', true, /\.js$/)
const RouterList = []
ModulesFile.keys().forEach((modulePath) => {
  RouterList.push(ModulesFile(modulePath).default)
})

Vue.use(VueRouter)
const DefaultContainer = () => import('@/views/containers/DefaultContainer')

const EmptyContainer = () => import('@/views/containers/EmptyContainer')

const routes = [
  {
    path: '/',
    component: DefaultContainer,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'web',
        name: 'Home',
        component: Home
      },
      ...RouterList,
      {
        path: 'ipc-disconnected',
        name: 'IpcDisconnected',
        component: () => import('@/views/pages/IpcDisconnected'),
        hidden: true,
        meta: {
          bgCSS: 'BG-04'
        }
      },
      {
        path: '/issue-report',
        name: 'IssueReport',
        component: () => import('@/views/pages/IssueReport'),
        hidden: true,
        meta: {
          bgCSS: 'BG-03',
          role: ['SCH_ADMIN', 'SCH_AFFAIRS']
        }
      },
      {
        path: 'log',
        name: 'OperateRecord',
        component: () => import('@/views/pages/OperateRecord'),
        meta: {
          name: '操作紀錄',
          bgCSS: 'BG-02',
          role: ['SYS_ADMIN', 'SCH_ADMIN', 'SCH_AFFAIRS'],
          permission: ['LOGOUT']
        }
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/pages/404'),
        hidden: true,
        meta: {}
      }
    ]
  },
  {
    path: '/',
    component: EmptyContainer,
    children: [
      {
        path: 'line',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'OAuth',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                meta: {
                  bgCSS: 'BG-03'
                },
                component: () => import('@/views/line/OAuth/index')
              },
              {
                path: 'callback',
                meta: {
                  bgCSS: 'BG-03'
                },
                props: (route) => route.query,
                component: () => import('@/views/line/OAuth/callback')
              }
            ]
          },
          {
            path: 'setting',
            component: () => import('@/views/line/Setting'),
            meta: {
              bgCSS: 'BG-03'
            }
          }
        ]
      },
      {
        path: 'openid',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'login',
            meta: {
              bgCSS: 'BG-03'
            },
            props: true,
            component: () => import('@/views/openid/login')
          },
          {
            path: 'callback',
            meta: {
              bgCSS: 'BG-03'
            },
            props: (route) => route.query,
            component: () => import('@/views/openid/callback')
          }
        ]
      },
      {
        path: 'web/openid',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'callback',
            meta: {
              bgCSS: 'BG-03'
            },
            props: (route) => route.query,
            component: () => import('@/views/openid/callback')
          }
        ]
      },
      {
        path: 'test',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'scenario',
            meta: {
              bgCSS: 'BG-03'
            },
            component: () => import('@/views/test/scenario')
          }
        ]
      },
      {
        path: 'login',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            component: () => import('@/views/pages/Login')
          },
          {
            path: 'origin',
            meta: {
              bgCSS: 'BG-03'
            },
            component: () => import('@/views/pages/OriginLogin')
          }
        ]
      },
      {
        path: 'school',
        component: () =>
          import('@/views/schoolDashboard/SchoolDashboardContainer'),
        children: [
          {
            path: 'dashboard/:schoolCode',
            props: true,
            component: () => import('@/views/schoolDashboard/index')
          }
          // {
          //   path: 'dashboard/page1',
          //   props: true,
          //   component: () => import('@/views/schoolDashboard/useEnergy')
          // }
        ]
      },
      {
        path: 'web/school',
        component: () =>
          import('@/views/schoolDashboard/SchoolDashboardContainer'),
        children: [
          {
            path: 'dashboard/:schoolCode',
            props: true,
            component: () => import('@/views/schoolDashboard/index')
          }
        ]
      },
      {
        path: 'device/connect/rate',
        name: 'SchoolConnectedRate',
        permission: ['LOGOUT'],
        component: () => import('@/views/admin/SchoolConnectedRate'),
        meta: {
          bgCSS: 'BG-02'
        }
      },
      {
        path: 'classroom/connect/rate',
        name: 'ClassroomConnectionRate',
        permission: ['LOGOUT'],
        component: () => import('@/views/pages/ClassroomConnectionRate'),
        meta: {
          bgCSS: 'BG-02'
        }
      },
      {
        path: 'admin',
        component: () => import('@/views/containers/AdminContainer'),
        children: [
          {
            path: '',
            props: true,
            permission: ['LOGOUT'],
            component: () => import('@/views/admin/index')
          },
          {
            path: 'sync',
            name: 'Sync',
            permission: ['LOGOUT'],
            component: () => import('@/views/admin/sync'),
            meta: {
              bgCSS: 'BG-02'
            }
          },
          {
            path: 'sync/city/:cityId',
            name: 'SyncCity',
            props: true,
            permission: ['LOGOUT'],
            component: () => import('@/views/admin/sync'),
            meta: {
              bgCSS: 'BG-02'
            }
          },
          {
            path: 'school/:schoolId/status',
            name: 'SchoolDeviceStatus',
            props: true,
            permission: ['LOGOUT'],
            component: () => import('@/views/admin/SchoolDeviceStatus'),
            meta: {
              bgCSS: 'BG-02'
            }
          },
          {
            path: 'manage',
            props: true,
            name: 'Manage',
            permission: ['LOGOUT'],
            component: () => import('@/views/admin/manage')
          },
          {
            path: 'city/:cityId',
            props: true,
            permission: ['LOGOUT'],
            component: () => import('@/views/admin/manage')
          },
          {
            path: 'role/:roleId',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'edit',
                props: true,
                component: () => import('@/views/admin/role/edit')
              }
            ]
          },
          {
            path: 'school/:schoolId',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'account',
                props: true,
                component: () => import('@/views/admin/school/account/index')
              }
            ]
          },
          {
            path: 'IPC',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                name: 'IPC',
                props: true,
                component: () => import('@/views/admin/IPC/index')
              },
              {
                path: 'school/:schoolId',
                name: 'SchoolIPC',
                props: true,
                component: () => import('@/views/admin/IPC/school')
              }
            ]
          },
          {
            path: 'ADR',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                name: 'adminADR',
                props: true,
                component: () => import('@/views/admin/ADR/index')
              }
            ]
          },
          {
            path: 'SSID',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: '',
                name: 'SSID',
                props: true,
                component: () => import('@/views/admin/SSID/index')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: settings.publicPath,
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.afterEach((to, from) => {
  if (
    to.name === 'CityOverview' &&
    process.env.VUE_APP_PUBLICPATH === '' &&
    process.env.VUE_APP_CITY === 'tainan'
  ) {
    history.replaceState(null, null, '/')
  }
})

export default router
