import Meter from '@/api/ems/Meter'

const state = {
  meter: [
    {
      label: '小時用電量',
      id: 'HOUR_ENERGY',
      types: ['hourEnergy'],
      labels: [],
      optionTypes: ['schoolMeter', 'classMeter', 'group']
    },
    {
      label: '日用電量',
      id: 'DAY_ENERGY',
      types: ['dayEnergy'],
      labels: [],
      optionTypes: ['schoolMeter', 'classMeter', 'group']
    },
    {
      label: '月用電量',
      id: 'MONTH_ENERGY',
      types: ['monthEnergy'],
      labels: [],
      optionTypes: ['schoolMeter', 'classMeter', 'group']
    },
    {
      label: '需量',
      id: 'DEMAND',
      types: ['demand'],
      labels: [],
      optionTypes: ['schoolMeter']
    },
    {
      label: '功率',
      id: 'POWER',
      types: ['power'],
      labels: ['功率'],
      optionTypes: ['schoolMeter', 'classMeter']
    },
    {
      label: '電流',
      id: 'CURRENT',
      types: ['current', 'currentR', 'currentS', 'currentT'],
      labels: ['電流', 'R相', 'S相', 'T相'],
      optionTypes: ['schoolMeter', 'classMeter']
    },
    {
      label: '電壓',
      id: 'VOLTAGE',
      types: ['voltage', 'voltageR', 'voltageS', 'voltageT'],
      labels: ['電壓', 'R相', 'S相', 'T相'],
      optionTypes: ['schoolMeter', 'classMeter']
    }
  ],
  solar: [
    {
      label: '逐時發電量',
      id: 'HOUR_ENERGY',
      types: ['hourEnergy'],
      labels: [],
      optionTypes: ['schoolMeter']
    },
    {
      label: '逐日發電量',
      id: 'DAY_ENERGY',
      types: ['dayEnergy'],
      labels: [],
      optionTypes: ['schoolMeter']
    },
    {
      label: '逐月發電量',
      id: 'MONTH_ENERGY',
      types: ['monthEnergy'],
      labels: [],
      optionTypes: ['schoolMeter']
    },
    {
      label: '發電功率',
      id: 'POWER',
      types: ['power'],
      labels: ['功率'],
      optionTypes: ['schoolMeter']
    }
  ],
  ac: [
    {
      label: '啟停',
      id: 'STATUS',
      types: ['airConditioningStatus'],
      labels: [],
      optionTypes: ['class']
    },
    {
      label: '運轉模式',
      id: 'MODE',
      types: ['operationMode'],
      labels: [],
      optionTypes: ['class']
    },
    {
      label: '設定溫度',
      id: 'TEMPERATURE',
      types: ['settingTemperature'],
      labels: [],
      optionTypes: ['class']
    }
  ],
  env: [
    {
      label: '室內溫度',
      id: 'roomTemperature',
      types: ['temperature'],
      labels: [],
      optionTypes: ['class']
    },
    {
      label: '室外溫度',
      id: 'temperature',
      types: ['temperature'],
      labels: [],
      optionTypes: [],
      noTarget: true
    },
    {
      label: '室外濕度',
      id: 'humidity',
      types: ['humidity'],
      labels: [],
      optionTypes: [],
      noTarget: true
    },
    {
      label: '室外風速',
      id: 'windSpeed',
      types: ['windSpeed'],
      labels: [],
      optionTypes: [],
      noTarget: true
    }
  ],
  schoolMeters: [],
  classroomMeters: []
}

const mutations = {
  SET_SCHOOL_METERS: (state, schoolMeters) => {
    state.schoolMeters = schoolMeters
  },
  SET_CLASSROOM_METERS: (state, classroomMeters) => {
    state.classroomMeters = classroomMeters
  }
}

const actions = {
  async getSchoolMeters({ commit }, tagId) {
    try {
      const { data } = await Meter.getSchoolMeters(tagId)
      commit('SET_SCHOOL_METERS', data || [])
    } catch (error) {
      console.error(error)
    }
  },
  async getClassroomMeters({ commit }, tagId) {
    try {
      const { data } = await Meter.getClassMeters(tagId)
      commit('SET_CLASSROOM_METERS', data || [])
    } catch (error) {
      console.error(error)
    }
  }
}

const getters = {
  meter: (state) => state.meter,
  solar: (state) => state.solar,
  ac: (state) => state.ac,
  env: (state) => state.env,
  schoolMeters: (state) => state.schoolMeters,
  classroomMeters: (state) => state.classroomMeters
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
