const cityOptionContainer = () =>
  import('@/views/containers/cityOptionContainer')

export default {
  path: 'city',
  redirect: '/',
  component: cityOptionContainer,
  props: true,
  meta: {},
  children: [
    {
      path: ':tagId',
      redirect: ':tagId/overview',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: 'overview',
          name: 'CityOverview',
          props: true,
          component: () => import('@/views/city/overview/index'),
          meta: {
            permission: ['LOGOUT']
          }
        },
        {
          path: 'report',
          props: true,
          component: () => import('@/views/city/report/index'),
          meta: {
            name: '統計報表',
            bgCSS: 'BG-02',
            permission: ['LOGOUT']
          }
        }
      ]
    }
  ]
}
